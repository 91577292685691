/**
 * @file Video.
 */

/**
 * Video component.
 */
const vVideo = () => {
	const video = document.querySelector('.v-video');

	if (!video) {
		return;
	}
	const overlay = document.querySelector('.v-video__overlay');
	const playButton = overlay.querySelector('.v-video__play-button');

	overlay.addEventListener('click', () => {});

	/**
	 *
	 */
	// Function to initialize the YouTube player
	function initYouTubePlayer() {
		const player = new YT.Player('youtube-video', {
			events: {
				onReady() {
					playButton.addEventListener('click', () => {
						overlay.style.display = 'none';
						player.playVideo();
					});
				},
			},
		});
	}

	// Function to load YouTube Iframe API and initialize the player
	/**
	 *
	 */
	function loadYouTubeIframeAPI() {
		if (typeof YT !== 'undefined' && YT && YT.Player) {
			initYouTubePlayer();
		} else {
			// If YT is not available, set a timeout to check again
			setTimeout(loadYouTubeIframeAPI, 100);
		}
	}

	loadYouTubeIframeAPI();
};

export default vVideo;
