const characterCounter = () => {
	const inputWithMax = document.querySelector('.extra-options .text-counter');

	if(!inputWithMax) {
		return;
	}

	const counterElem = document.createElement('span');
	counterElem.classList.add('text-counter-label')

	counterElem.textContent = `${inputWithMax.value.length} out of ${inputWithMax.getAttribute('maxlength')} max characters`;
	inputWithMax.insertAdjacentElement('afterend', counterElem);

	inputWithMax.addEventListener('keyup', () => {
		const counterText = document.querySelector('.text-counter-label');
		counterText.textContent = `${inputWithMax.value.length} out of ${inputWithMax.getAttribute('maxlength')} max characters`;
	});
}

export default characterCounter;
