/**
 * @file Mini Cart.
 */

/**
 * Mini Cart component.
 */
const vMiniCart = () => {
	const miniCart = document.querySelector('.v-mini-cart');

	if (!miniCart) {
		return;
	}

	const miniCartButton = document.querySelector('header li.v-nav-cart');
	miniCartButton.addEventListener('mouseenter', () => {
		miniCart.classList.add('show');
	});

	miniCart.addEventListener('mouseleave', () => {
		miniCart.classList.remove('show');
	});
};

export default vMiniCart;
