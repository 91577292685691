/**
 * @file Off Canvas Nav.
 */

import { gsap } from 'gsap';
import * as focusTrap from 'focus-trap';

/**
 * Off Canvas Nav component.
 */
const vOffCanvasNav = () => {
	const offCanvasNavs = document.querySelectorAll('.v-off-canvas-nav');

	if (!offCanvasNavs) {
		return;
	}

	const navClasses = {};

	class OffCanvasNav {
		constructor(offCanvasNav) {
			this.canvas = offCanvasNav;
			this.overlay = offCanvasNav.querySelector('.v-off-canvas-nav__overlay');
			this.wrap = offCanvasNav.querySelector('.v-off-canvas-nav__wrap');
			this.closers = offCanvasNav.querySelectorAll('.v-off-canvas-nav__close');
			this.panels = offCanvasNav.querySelectorAll('.v-off-canvas-nav__panel');
			this.isOpen = false;
			this.isAnimating = false;
			this.panelTriggers = offCanvasNav.querySelectorAll(
				'.v-off-canvas-nav__nav-link--trigger',
			);
			this.backTriggers = offCanvasNav.querySelectorAll(
				'.v-off-canvas-nav__back',
			);
			this.trap = null;
			this.trigger = null;

			this.init();
		}

		open(trigger = null) {
			if (this.isAnimating) {
				return;
			}

			if (trigger) {
				this.trigger = trigger;
			}

			this.isAnimating = true;
			this.isOpen = true;
			this.canvas.classList.add('v-off-canvas-nav--open');
			gsap
				.timeline({
					defaults: {
						duration: 0.4,
						ease: 'sine.out',
					},
					onComplete: () => {
						this.isAnimating = false;
						const currentPanel = [
							...this.canvas.querySelectorAll(
								'.v-off-canvas-nav__panel:not(.v-off-canvas-nav__panel--covered)',
							),
						].filter((p) => gsap.getProperty(p, 'display') === 'block')[0];

						console.log('Initializing focus trap for:', currentPanel);

						this.trap = focusTrap
							.createFocusTrap(currentPanel, {
								setReturnFocus: this.trigger,
								allowOutsideClick: true,
								onDeactivate: () => {
									this.close();
								},
							})
							.activate();
					},
				})
				.to(this.overlay, {
					opacity: 1,
				})
				.to(
					this.wrap,
					{
						x: 0,
					},
					'<',
				);
		}

		close() {
			if (this.isAnimating) {
				return;
			}
			this.isAnimating = true;
			this.isOpen = false;
			gsap
				.timeline({
					defaults: {
						duration: 0.4,
						ease: 'sine.out',
					},
					onComplete: () => {
						this.isAnimating = false;

						if (this.trap) {
							// console.log('Deactivating focus trap:', this.trap); // Check before deactivation
							// this.trap.deactivate();
							try {
								this.trap.deactivate();
								// console.log('Focus trap deactivated');
							} catch (error) {
								// console.error('Error deactivating focus trap:', error); // Catch any errors during deactivation
							}
						}
						this.canvas.classList.remove('v-off-canvas-nav--open');
					},
				})
				.to(this.overlay, {
					opacity: 0,
				})
				.to(
					this.wrap,
					{
						x: '100%',
					},
					'<',
				);
		}

		openPanel(panelTrigger) {
			if (this.isAnimating) {
				return;
			}
			console.log('trigger clicked');
			this.isAnimating = true;

			const item = panelTrigger.closest('.v-off-canvas-nav__nav-item');
			const panel = item.querySelector(':scope > .v-off-canvas-nav__panel');
			const parentPanel = item.closest('.v-off-canvas-nav__panel');

			parentPanel.classList.add('v-off-canvas-nav__panel--covered');
			gsap.set(panel, {
				display: 'block',
			});

			gsap.to(panel, {
				x: 0,
				ease: 'sine.out',
				duration: 0.4,
				onComplete: () => {
					this.isAnimating = false;
					this.trap.pause();
					this.trap = focusTrap
						.createFocusTrap(panel, {
							allowOutsideClick: true,
							setReturnFocus: this.trigger,
							onDeactivate: () => {
								this.close();
							},
						})
						.activate();
				},
			});
		}

		closePanel(backTrigger) {
			if (this.isAnimating) {
				return;
			}

			this.isAnimating = true;

			const panel = backTrigger.closest('.v-off-canvas-nav__panel');
			const item = panel.closest('.v-off-canvas-nav__nav-item');
			const parentPanel = item.closest('.v-off-canvas-nav__panel');

			parentPanel.classList.remove('v-off-canvas-nav__panel--covered');
			gsap.to(panel, {
				x: '100%',
				ease: 'sine.out',
				duration: 0.4,
				onComplete: () => {
					gsap.set(panel, {
						display: 'none',
					});
					this.trap.pause();
					this.trap = focusTrap
						.createFocusTrap(parentPanel, {
							allowOutsideClick: true,
							setReturnFocus: this.trigger,
							onDeactivate: () => {
								this.close();
							},
						})
						.activate();

					this.isAnimating = false;
				},
			});
		}

		init() {
			this.canvas.addEventListener('vOffCanvasNavOpen', () => {
				console.log(this.isOpen);
				if (this.isOpen) {
					return;
				}

				this.open();
			});

			this.overlay.addEventListener('click', () => {
				if (!this.isOpen) {
					return;
				}

				this.close();
			});

			[...this.closers].forEach((close) => {
				close.addEventListener('click', () => {
					if (!this.isOpen) {
						return;
					}

					this.close();
				});
			});

			[...this.panelTriggers].forEach((trigger) => {
				trigger.addEventListener('click', (e) => {
					e.preventDefault();
					this.openPanel(trigger);
				});
			});

			[...this.backTriggers].forEach((trigger) => {
				trigger.addEventListener('click', (e) => {
					e.preventDefault();
					this.closePanel(trigger);
				});
			});
		}
	}

	[...offCanvasNavs].forEach((offCanvasNav) => {
		navClasses[offCanvasNav.getAttribute('id')] = new OffCanvasNav(
			offCanvasNav,
		);
	});

	const navTriggers = document.querySelectorAll('[data-off-canvas-nav]');
	[...navTriggers].forEach((trigger) => {
		const target = trigger.dataset.offCanvasNav;
		const nav = navClasses[target];
		if (nav) {
			trigger.addEventListener('click', (e) => {
				e.preventDefault();
				if (Object.values(navClasses).filter((n) => n.isOpen).length) {
					Object.values(navClasses)
						.filter((n) => n.isOpen)[0]
						.close();
				}
				nav.open(trigger);
			});
		}
	});
};

export default vOffCanvasNav;
