/**
 * Module:  Find a Dealer
 * @description	aggregate results using a show-more button
 */

/**
 * Constructor
 * @param {object} options Optional properties to override defaults
 */
function FindADealer(options) {
	this.init();
}

/**
 * Insert the show-more button.
 */
function insertShowMore() {
	let showMore = document.getElementById('find-a-dealer-show-more');
	if (showMore) {
		showMore.remove();
	}

	let hiddenListings = document.querySelectorAll('.wpsl-listing.hidden');
	if (hiddenListings.length > 0) {
		const visibleListings = document.querySelectorAll(
			'.wpsl-listing:not(.hidden)',
		);
		const lastVisibleListing = visibleListings[visibleListings.length - 1];

		const newShowMore = document.createElement('div');
		newShowMore.id = 'find-a-dealer-show-more';
		newShowMore.className = 'find-a-dealer-show-more-btn';
		newShowMore.innerHTML =
			'<button class="v-button show-more-btn"><span class="show-more">Load More</span></button>';

		lastVisibleListing.parentNode.insertBefore(
			newShowMore,
			lastVisibleListing.nextSibling,
		);

		showMore = document.querySelector('#find-a-dealer-show-more .show-more-btn');
		showMore.addEventListener('click', () => {
			hiddenListings = document.querySelectorAll('.wpsl-listing.hidden');
			const sliceEndIndex = Math.min(hiddenListings.length, 10);
			for (let i = 0; i < sliceEndIndex; i++) {
				hiddenListings[i].classList.remove('hidden');
			}
			insertShowMore();
		});
	}
}

/**
 * Setup
 */
FindADealer.prototype.init = function () {
	if (FindADealer) {
		let numListings = 0;

		document
			.querySelector('body')
			.addEventListener('DOMSubtreeModified', () => {
				const newNumListings = document.querySelectorAll(
					'.wpsl-listing:not(#find-a-dealer-show-more)',
				).length;
				if (newNumListings > 10 && newNumListings > numListings) {
					numListings = newNumListings;
					const elements = document.querySelectorAll('.wpsl-listing');
					const elementsArray = Array.prototype.slice.call(elements);
					const slicedElements = elementsArray.slice(10);
					slicedElements.forEach((element) => {
						element.classList.add('hidden');
					});
					insertShowMore();
				}
			});

		let filtersOpen = true;

		const toggleFilters = (e) => {
			e.preventDefault();
			filtersOpen = !filtersOpen;
			const wpslSearchWrap = document.getElementById('wpsl-search-wrap');
			const icon = document.querySelector('#wpsl-form-actions .icon');
			if (
				wpslSearchWrap.style.display === 'none' ||
				wpslSearchWrap.style.display === ''
			) {
				wpslSearchWrap.style.display = 'block';
			} else {
				wpslSearchWrap.style.display = 'none';
			}
			icon.textContent = filtersOpen ? '−' : '+';
		};

		document.querySelectorAll('#wpsl-form-actions a').forEach((element) => {
			element.addEventListener('click', toggleFilters);
			element.addEventListener('touchstart', toggleFilters);
		});

		// Remove random 1 that appears at bottom of list
		const element = document.querySelector('.v-dealer-listing');
		if(element) {
			const randomOne = element.childNodes[2];
			if(randomOne) {
				randomOne.textContent = '';
			}
		}

		// Allow map to update on radius change
		// setTimeout(() => {
		// 	const radius = document.querySelector(".wpsl-selected-item");

		// 	if(radius) {
		// 		radius.addEventListener('DOMSubtreeModified', () => {
		// 			const submitBtn = document.getElementById('wpsl-search-btn');
		// 			submitBtn.click();
		// 		});
		// 	}
		// }, 1000);


	}
};

/**
 * Public API
 */
module.exports = {
	init(opts) {
		return new FindADealer(opts);
	},
};
