/**
 * @file Single Resource Accordion.
 */

import { gsap } from 'gsap';

/**
 * Single Resource Accordion component.
 */
const vSingleResourceAccordion = () => {
	const accordion = document.querySelectorAll(
		'.v-single-resource-accordion__accordion',
	);
	const allAccordionContent = document.querySelectorAll(
		'.v-single-resource-accordion__accordion-content',
	);
	const allPlusIcons = document.querySelectorAll(
		'.v-single-resource-accordion__plus-icon',
	);
	const allMinusIcons = document.querySelectorAll(
		'.v-single-resource-accordion__minus-icon',
	);

	if (!accordion) {
		return;
	}

	gsap.set(allMinusIcons, {
		autoAlpha: 0,
	});

	gsap.set(allAccordionContent, {
		autoAlpha: 0,
	});

	accordion.forEach((el) => {
		const accordionContent = el.querySelector(
			'.v-single-resource-accordion__accordion-content',
		);
		const plusIcon = el.querySelector(
			'.v-single-resource-accordion__plus-icon',
		);
		const minusIcon = el.querySelector(
			'.v-single-resource-accordion__minus-icon',
		);

		el.addEventListener('click', () => {
			// Check if clicked acc is currently open
			const isOpen = gsap.getProperty(accordionContent, 'display') !== 'none';

			// Close all accordions
			gsap.set(allAccordionContent, {
				display: 'none',
				autoAlpha: 0,
			});
			gsap.set(allMinusIcons, {
				autoAlpha: 0,
			});
			gsap.set(allPlusIcons, {
				autoAlpha: 1,
			});

			// Toggle the clicked accordion based on its current state
			if (!isOpen) {
				// Show clicked accordion if it was closed
				gsap.set(accordionContent, {
					display: 'flex',
				});
				gsap.to(accordionContent, {
					autoAlpha: 1,
				});
				gsap.set(plusIcon, {
					autoAlpha: 0,
				});
				gsap.to(minusIcon, {
					autoAlpha: 1,
				});
			}
		});
	});
};

export default vSingleResourceAccordion;
