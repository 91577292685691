/**
 * @file Search Form.
 */

import { gsap } from 'gsap';
import * as focusTrap from 'focus-trap';

/**
 * Search Form component.
 */
const vSearchForm = () => {
	const searchForm = document.querySelector('.v-search-form');

	if (!searchForm) {
		return;
	}

	let animation;
	const field = searchForm.querySelector('.v-search-form__field');
	const trap = focusTrap.createFocusTrap( searchForm, {
		initialFocus: field,
		onDeactivate: () => {
			if (animation) {
				animation.timeScale(1.5).reverse();
			}
		}
	});

	animation = gsap.timeline({
		defaults: {
			ease: 'sine.out',
			duration: .4,
		},
		paused: true,
		onStart: () => {
			gsap.set( searchForm, { display: 'block' } );
			trap.activate();
		},
		onReverseComplete: () => {
			gsap.set( searchForm, { display: 'none' } );
			trap.deactivate();
		},
	})
		.from('.v-search-form__overlay', { opacity: 0 })
		.from('.v-search-form__close, .v-search-form__form', {
			opacity: 0,
			y: 10,
		})
		.from('.v-search-form__suggestions-title, .v-search-form__suggestion', {
			opacity: 0,
			y: 10,
			stagger: .1,
		});

	const triggers = document.querySelectorAll('.v-search-form-trigger');
	[...triggers].forEach(trigger => {
		trigger.addEventListener('click', () => {
			animation.timeScale(1).play();
		})
	});

	const close = searchForm.querySelector('.v-search-form__close');
	close.addEventListener('click', () => {
		animation.timeScale(1.5).reverse();
	});
};

export default vSearchForm;
