/**
 * @file Full Screen Bg Text.
 */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

/**
 * Full Screen Bg Text component.
 */
const vFullScreenBgText = () => {
	const fullScreenBgText = document.querySelectorAll('.v-full-screen-bg-text');
	const fullScreenBgTextAnimate = document.querySelectorAll(
		'.v-full-screen-bg-text__animate',
	);

	if (!fullScreenBgText) {
		return;
	}

	// Handle dropdown select links
	const bgTextSelectField = document.getElementById(
		'v-full-screen-bg-text__select',
	);
	if (bgTextSelectField) {
		bgTextSelectField.addEventListener('change', function () {
			const url = this.value;
			if (url !== 'default') {
				window.open(url, '_blank');
			}
		});
	}

	// Animations
	const mm = gsap.matchMedia();

	fullScreenBgTextAnimate.forEach((element) => {
		const elContent = element.querySelector('.v-full-screen-bg-text__content');
		const elLeftBar = element.querySelector(
			'.v-full-screen-bg-text__anim-bar-left',
		);
		const elRightBar = element.querySelector(
			'.v-full-screen-bg-text__anim-bar-right',
		);

		mm.add('(max-width: 1199px)', () => {
			gsap.set([elLeftBar, elRightBar], {
				autoAlpha: 0,
				display: 'none',
			});
			gsap.set(elContent, {
				y: 0,
			});
		});

		mm.add('(min-width: 1200px)', () => {
			gsap.set([elLeftBar, elRightBar], {
				autoAlpha: 1,
				display: 'block',
			});
			gsap.to(elLeftBar, {
				scrollTrigger: {
					trigger: element,
					start: 'top center',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
				x: '-100%',
			});
			gsap.to(elRightBar, {
				scrollTrigger: {
					trigger: element,
					start: 'top center',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
				x: '100%',
			});
			gsap.from(elContent, {
				y: 200,
				scrollTrigger: {
					trigger: elContent,
					start: 'top bottom',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
			});
		});
	});
};

export default vFullScreenBgText;
