/**
 * Module:  Account
 * @description	JS interface for accounts
 */

/**
 * Constructor
 * @param {object} options Optional properties to override defaults
 */
function Account(options) {
	this.init();
}

/**
 * Setup
 */
Account.prototype.init = () => {
	if (Account) {
		let inputs = document.querySelector('.registration-roles');
		if (inputs) {
			inputs = inputs.querySelectorAll('input');

			[...inputs].forEach((input) => {
				input.addEventListener('change', (e) => {
					if (e.target.value === 'dealer') {
						document.querySelector('.registration-customer-id').style.display =
							'block';
					} else {
						document.querySelector('.registration-customer-id input').value =
							'';
						document.querySelector('.registration-customer-id').style.display =
							'none';
					}
				});
			});
		}
	}
};

/**
 * Public API
 */
module.exports = {
	init(opts) {
		return new Account(opts);
	},
};
